<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <div class="content_box" ref="header">
            <div class="content max_small_box">
                <div class="list" v-for="(item, index) in list" :key="index" data-aos="fade-up" :data-aos-delay="(index+1)*200">
                    <div class="video">
                        <video :src="item.file" :poster="imgUrl + item.image"  @click="() => openVideo(item.file)"></video>
                    </div>
                    <div class="text">
                        <p class="title">{{item.title}}</p>
                        <p class="desc">{{item.intro}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination max_small_box">
            <a-pagination :default-current="page" :total="total_page" @change="handleChangePage" />
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import ResourcesTop from '../../components/resources/ResourcesTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

const listEffect = (route, header) => {
    //列表
    const list = ref()

    //页数
    const page = ref(1)

    //总页数
    const total_page = ref()

    //获取详情
    const getList = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
            page: page.value
        }
        await post('/resource/get_resource', postData).then((res) => {
            // console.log('宣传视频列表', res)
            list.value = res.data.data
            total_page.value = res.data.total
        })
    }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        page.value = new_page
        document.documentElement.scrollTop = document.body.scrollTop = header.value.offsetTop - 60;
    }

    watchEffect(() =>{
        getList(route.params.id, route.params.page_id)
    })

    const openVideo = (file) => {
        window.open(file,"","width=800,height=600,top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
    }

    return { list, page, total_page, getList, handleChangePage, openVideo }
}

export default {
    name: 'ResourcesVideo',
    components: { Header, Footer, FloatingFrame, ResourcesTop },
    setup() {
        const  route = useRoute()

        //获取滑块到顶部的距离
        const header = ref(null)

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        const { list, page, total_page, getList, handleChangePage, openVideo } = listEffect(route, header)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });
        const { imgUrl }  = api

        return {  imgUrl, isShow, header, list, page, total_page, handleChangePage, openVideo }
    }
}
</script>

<style lang="scss" scoped>
.content_box{
    margin-top: 50px;
}
.content{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    @media screen and (max-width: 900px){
        display: block;
    }
    .list{
        width: 22%;
        background-color: #F4F4F4;    
        margin-right: 4%;
        margin-bottom: 30px;
        cursor: pointer;
        &:nth-child(4n){
            margin-right: 0%;
        }
        @media screen and (max-width: 900px){
            width: 100%;
            margin-right: 0%;
        }
        .video{
            width: 100%;
            height: 200px;
            @media screen and (max-width: 900px){
                height: auto;
            }
            video{
                width: 100%;
                height: 100%;
            }
        }
        .text{
            padding: 10px 15px;
            .title{
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                font-weight: 600;
                margin-top: 15px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
            .desc{
                color: rgba(0, 0, 0, 1);
                font-size: 14px;
                margin-top: 15px;
                line-height: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            }
        }
    }
}
.pagination{
    text-align: center;
    padding-top: 80px;
    display: block;
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>